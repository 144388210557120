<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row>
                <v-overlay :value="overlayDesgarga" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{frase}}</p>
                    </div>
                </v-overlay>
                <v-overlay :value="dialogOverlaySocket" :absolute="true" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{'Subiendo puestos'}} - {{socketPorcentaje}} %</p>
                        <p>Procesados {{socketProcesados}} de {{socketTotal}}</p>
                    </div>
                </v-overlay> 
                <v-col cols="12" xs="12" sm="12" md="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Registros patronales'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn 
                                    color="#004BAF" 
                                    class="btnAdd" 
                                    right
                                    rounded 
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template
                            v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'"
                            slot="actionButtonsLeft"
                        >
                            <v-menu offset-y content-class="menuClassAcciones">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Acciones 
                                        <template  v-for="(item,valor) in attrs" >
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                        </template>
                                    </v-btn>
                                    
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div class="btnAccionesTitulo pb-6">
                                        <span>Layout de Registros Patronales</span>
                                    </div>

                                    <template>
                                        <div @click="descargarLayout()" class="btnAccionesItem py-2">
                                            <span>Descargar layout</span>
                                        </div>
                                        <div @click="abrirModalLayout()" class="btnAccionesItem py-2">
                                            <span>Importar layout para agregar Registros Patronales</span><br />
                                        </div>
                                    </template>
                                </div>
                            </v-menu>
                        </template>
                         <template slot="filters">
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="cliente_value"
                                        no-data-text="Datos no disponibles"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <v-col xs="12" sm="6" md="6" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="RFC"
                                    maxlength="13"
                                    v-model="rfc"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="Registro Patronal"
                                    v-model="regimen_patronal"
                                ></v-text-field>
                            </v-col>
                           
                            </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.clave}}</div></td>
                                <td><div>{{item.nombre}}</div></td>
                                <td><div>{{item.registro_patronal}}</div></td>
                                <td><div>{{item.rfc}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('bancos', item)" small>
                                                    <v-icon class="tamIconoBoton iconoDelgadoBoton outlined_v_icon">account_balance</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Bancos</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('update', item)" small>
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-dialog v-model="dialog" persistent max-width="700px" scrollable>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <perfect-scrollbar style="background: #fff">
                    <v-card elevation="0">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    required 
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Clave" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Clave" 
                                                    class="pa-0 ma-0" 
                                                    v-model="registroPatronal.clave" 
                                                    :error-messages="errors" 
                                                    required
                                                >    
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Nombre" 
                                                    class="pa-0 ma-0" 
                                                    v-model="registroPatronal.nombre" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                        
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Registro Patronal" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Registro patronal" 
                                                    class="pa-0 ma-0" 
                                                    v-mask="'ANN-#####-NN-#'"
                                                    v-model="registroPatronal.registro_patronal" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                               
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="RFC" rules="required|rfc">
                                                <v-text-field 
                                                    outlined 
                                                    label="RFC" 
                                                    class="pa-0 ma-0" 
                                                    maxlength="13"
                                                    v-model="registroPatronal.rfc" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                               
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="CURP" :rules="disableCURP ? '':'required|curp'">
                                                <v-text-field 
                                                    outlined
                                                    label="CURP" 
                                                    maxlength="18"
                                                    class="pa-0 ma-0" 
                                                    v-model="registroPatronal.curp"
                                                    :error-messages="errors" 
                                                    required
                                                    id="txtCURP"
                                                    :disabled="disableCURP"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Régimen Fiscal" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Régimen Fiscal"
                                                    :items="regimenesFiscales"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="registroPatronal.regimen_fiscal_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Calle" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Calle" 
                                                    class="pa-0 ma-0" 
                                                    v-model="registroPatronal.calle" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                                
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Número interior" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Número interior" 
                                                    class="pa-0 ma-0" 
                                                    v-model="registroPatronal.numero_interior"
                                                    :error-messages="errors" 
                                                    required
                                                >                                            
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <v-text-field 
                                                outlined 
                                                label="Número exterior" 
                                                class="pa-0 ma-0" 
                                                v-model="registroPatronal.numero_exterior" 
                                            >                                            
                                            </v-text-field>
                                        </v-col>

                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Código postal"
                                                    v-model="busca_cp"
                                                    :error-messages="errors"
                                                    @keyup.enter="busca_codigos()"
                                                    v-on:keydown.tab="busca_codigos()"
                                                    v-mask="'#####'"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <v-text-field
                                                outlined
                                                label="Estado"
                                                v-model="estado"
                                                disabled
                                            />
                                        </v-col>

                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <v-text-field
                                                outlined
                                                label="Municipio"
                                                v-model="municipio"
                                                disabled
                                            />
                                        </v-col>

                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="colonias"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Colonia"
                                                    persistent-hint
                                                    v-model="registroPatronal.colonia_id"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                >
                                                    <template v-if="mColonia"  v-slot:append-item>
                                                        <v-list-item>             
                                                            <v-list-item-content class="cursor-pointer"  @click="abrirModalColonia()">
                                                                <v-list-item-title style="color:#007EFF">Agregar colonia</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>




                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Telefono" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Teléfono" 
                                                    class="pa-0 ma-0" 
                                                    v-mask="'(###) ###-####'" 
                                                    placeholder="(999) 999-9999" 
                                                    v-model="registroPatronal.telefono" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                                
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Representante" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Representante" 
                                                    class="pa-0 ma-0" 
                                                    v-model="registroPatronal.representante" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                                
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Prima de riesgo" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Prima de riesgo" 
                                                    suffix="%"
                                                    class="pa-0 ma-0"
                                                    min="0"
                                                    max="100"
                                                    v-on:keypress="presicionNumeroPorcentaje($event,registroPatronal.riesgo_de_trabajo)"
                                                    oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                    v-model="registroPatronal.riesgo_de_trabajo" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                                
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Clase" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Clase"
                                                    :items="clases"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="registroPatronal.clase_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Apoderado" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Apoderado" 
                                                    class="pa-0 ma-0" 
                                                    v-model="registroPatronal.apoderado" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                               
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Actividad comercial" rules="required">
                                                <v-textarea
                                                    v-model="registroPatronal.actividad_comercial"
                                                    outlined
                                                    label="Actividad comercial"
                                                    :error-messages="errors"
                                                    required
                                                    rows="3"
                                                ></v-textarea>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-textarea
                                                v-model="direccionContrato"
                                                outlined
                                                label="Dirección contrato"
                                                rows="3"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col v-if="accion=='add'" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                            <v-file-input
                                                outlined
                                                label="Seleccionar logo"
                                                v-model="archivoImagen"
                                                accept="image/*"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                        </v-col>
                                        <v-col v-if="accion=='update' && registroPatronal.logo == null" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                            <v-file-input
                                                outlined
                                                label="Seleccionar logo"
                                                v-model="archivoImagen"
                                                accept="image/*"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                        </v-col>
                                        <v-col v-if="accion=='update' && registroPatronal.logo != null" cols="12" xs="12" sm="5" md="5" class="pt-0 pb-0 ma-0">
                                            <v-file-input
                                                outlined
                                                label="Seleccionar logo"
                                                v-model="archivoImagen"
                                                accept="image/*"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                        </v-col>
                                        <v-col v-if="accion=='update' && registroPatronal.logo != null" cols="12" xs="12" sm="1" md="1" class="pt-2">
                                            <v-tooltip bottom >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="eliminarLogo()" color="#1E2245" elevation="0" dark fab small>
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar logo</span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="tituloOtrasAcciones">Otras acciones</p>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0 d-flex">
                                            <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="registroPatronal.cuota_obrera" label="Cuota obrera IMSS integrada al Patronal"></v-checkbox>
                                            <v-tooltip top class="pb-7">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                </template>
                                                <span class="pb-7 textTooltip">
                                                    Cuando el empleado tenga salario mínimo <br>
                                                    más percepciones, la cuota obrera será <br> 
                                                    integrada dentro de la cuota Patronal.
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0 d-flex">
                                            <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="registroPatronal.calculo_prima_vacacional" label="Generar prima vacacional"></v-checkbox>
                                            <v-tooltip top class="pb-7">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                </template>
                                                <span class="pb-7 textTooltip">
                                                    Se genera automáticamente el complemento <br>
                                                    de nómina al cumplir el aniversario del empleado.
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0 d-flex">
                                            <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="registroPatronal.activar_dos_contratos" label="Generar dos contratos"></v-checkbox>
                                            <v-tooltip top class="pb-7">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                </template>
                                                <span class="pb-7 textTooltip">
                                                    Se genera dos contratos, por ejemplo: <br>
                                                    un contrato por tiempo determinado y <br>
                                                    otro por tiempo indeterminado
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Tipo de fecha para cálculo" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Tipo de fecha para cálculo"
                                                    :items="itemsTipoFechaCalculo"
                                                    item-text="descripcion"
                                                    item-value="valor"
                                                    persistent-hint
                                                    v-model="registroPatronal.fecha_calculo"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>

                                    
                                        <template v-if="showODESSA">
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0 pt-3">
                                                <p class="tituloOtrasAcciones">ODESSA</p>
                                            </v-col>
                                            
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <v-text-field 
                                                    outlined 
                                                    label="Clave ODESSA" 
                                                    class="pa-0 ma-0" 
                                                    v-model="registroPatronal.clave_odesa"
                                                >                                               
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 d-flex">
                                                <v-text-field 
                                                    outlined 
                                                    label="Días de antigüedad empleados" 
                                                    class="pa-0 ma-0" 
                                                    v-model="registroPatronal.antiguedad_fondo_ahorro"
                                                    v-mask="'####'"
                                                >                                               
                                                </v-text-field>
                                                <v-tooltip top class="pb-7">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2">info_outline</v-icon>
                                                    </template>
                                                    <span class="textTooltip">
                                                        Días de antigüedad que sirve para realizar el proceso de Fondo y Caja de Ahorro <br>
                                                    </span>
                                                </v-tooltip>
                                            </v-col>

                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <v-radio-group class="ma-0 pa-0" v-model="tipo_descuento" row>
                                                    <v-radio label="Porcentaje" value="porcentaje"></v-radio>
                                                    <v-radio label="Monto" value="monto"></v-radio>
                                                </v-radio-group>
                                            </v-col>

                                            <v-col v-if="tipo_descuento == 'porcentaje'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Porcentaje" rules="required">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Porcentaje" 
                                                        suffix="%"
                                                        class="pa-0 ma-0"
                                                        min="0"
                                                        max="100"
                                                        v-on:keypress="presicionNumeroPorcentaje($event,monto_fa)"
                                                        oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                        v-model="monto_fa" 
                                                        :error-messages="errors" 
                                                        required
                                                    >                                                
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            
                                            <v-col v-if="tipo_descuento == 'monto'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyMoneyValidate
                                                    rules="required"
                                                    outlined
                                                    label="Monto"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="monto_fa"
                                                    v-bind:options="options"
                                                />
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </perfect-scrollbar>
            </v-dialog>
        </template>
        <template>
            <v-dialog v-model="dialogBanco" persistent max-width="800px" scrollable>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalBanco"></v-btn>
                </template>
                <perfect-scrollbar style="background: #fff">
                    <v-card elevation="0">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                outlined
                                                label="Cuenta bancaria"
                                                v-model="cuenta" 
                                                id="cuenta"
                                                v-mask="'########################'"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <v-text-field 
                                                outlined
                                                label="Clabe interbancaria" 
                                                maxLength="18"
                                                v-model="clabe"
                                                id="clabe"
                                                v-mask="'##################'"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <v-text-field 
                                                outlined
                                                label="Cuenta ordenante" 
                                                maxLength="20"
                                                v-model="ordenante"
                                                id="ordenante"
                                                v-mask="'####################'"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0 d-flex">
                                            <v-text-field 
                                                outlined
                                                label="Número de información" 
                                                maxLength="20"
                                                v-model="emisora"
                                                id="emisora"
                                                v-mask="'####################'"
                                                class="input-field"
                                            >
                                            </v-text-field>
                                            <v-tooltip top class="pb-7">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2">info_outline</v-icon>
                                                </template>
                                                <span class="textTooltip">
                                                    Número solicitado por ciertos bancos, por ejemplo:  en <br>
                                                    Banorte se nombra "Número de emisora" , en Bancomer <br>
                                                    "Número de contrato de nómina", y en Banamex es <br>
                                                    "Número de identificación del cliente".
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                label="Banco"
                                                :items="bancos"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="banco_id"
                                                id="txtBanco"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                outlined
                                                label="Referencia"
                                                v-model="referencia" 
                                                id="referencia"
                                                v-mask="'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN'"
                                            ></v-text-field>
                                        </v-col>
                                        
                                    </v-row>
                                    <v-row class="pa-0 ma-0">
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 d-flex justify-end">
                                            <v-btn color="#1E2245" elevation="0" dark fab  @click="agregarCuenta()" small>
                                                <v-icon dark>add</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col v-if="cuentasBancarias.length > 0" cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 pr-0 ma-0">
                                            <v-divider class="pb-8 mt-6"></v-divider>
                                        </v-col>
                                        <template v-for="(i,index) in cuentasBancarias">
                                            <v-col cols="12" xs="12" sm="4" md="4" :key="index" class="pt-0 pb-0 ma-0">
                                                <v-autocomplete 
                                                label="Banco" 
                                                outlined
                                                :items="bancos" 
                                                item-text="nombre"
                                                item-value="id"
                                                :disabled="(indexBanco == index && isDisabled == false) ? false : true" 
                                                type="text" 
                                                :value="i.banco_id"
                                                v-model="i.banco_id"
                                                id="txtBanco"
                                                no-data-text="Datos no disponibles"></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="4" md="4" :key="'A'+index" class="pt-0 pb-0 ma-0">
                                                <v-text-field 
                                                type="text" 
                                                label="Cuenta bancaria" 
                                                outlined 
                                                :value="i.cuenta_bancaria" 
                                                v-model="i.cuenta_bancaria"
                                                v-mask="'########################'"
                                                :disabled="(indexBanco == index && isDisabled == false) ? false : true" ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="4" md="4" :key="'B'+index" class="pt-0 pb-0 ma-0">
                                                <v-text-field 
                                                type="text" 
                                                label="Clabe interbancaria" 
                                                outlined 
                                                :value="i.clabe_bancaria"
                                                v-model="i.clabe_bancaria"
                                                v-mask="'##################'"
                                                :disabled="(indexBanco == index && isDisabled == false) ? false : true" ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="4" md="4" :key="'C'+index" class="pt-0 pb-0 ma-0">
                                                <v-text-field 
                                                type="text" 
                                                label="Cuenta ordenante" 
                                                outlined 
                                                :value="i.cuenta_ordenante" 
                                                v-model="i.cuenta_ordenante"
                                                v-mask="'####################'"
                                                :disabled="(indexBanco == index && isDisabled == false) ? false : true" ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="4" md="4" :key="'D'+index" class="pt-0 pb-0 ma-0">
                                                <v-text-field 
                                                type="text" 
                                                label="Cuenta emisora" 
                                                outlined 
                                                :value="i.cuenta_emisora"
                                                v-model="i.cuenta_emisora"
                                                :disabled="(indexBanco == index && isDisabled == false) ? false : true" ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="4" md="4" :key="'E'+index" class="pt-0 pb-0 ma-0">
                                                <v-text-field 
                                                type="text" 
                                                label="Referencia" 
                                                outlined 
                                                :value="i.referencia" 
                                                v-model="i.referencia"
                                                :disabled="(indexBanco == index && isDisabled == false) ? false : true" ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" :key="'W'+index" class="py-0 mt-0 d-flex justify-end align-center">
                                                <v-switch class="mb-1 " v-model="i.principal" :disabled="i.principal" @change="cuentaPrincipal(index)"></v-switch>
                                                <v-btn class="botonHover" icon fab dark small  @click="editarCuenta(index,i)" >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                                <v-btn class="botonHover" icon fab dark small :disabled="i.principal"  @click="eliminaCuenta(index, i)" >
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" :key="'Z'+index" class="pt-0 pb-7 pr-0 ma-0 mt-5">
                                                <v-divider :key="'D'+index" class=""></v-divider>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions class="d-flex justify-end card_accion pt-15">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    :loading="isSaving"
                                    @click="guardarBancos()"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                    </v-card>
                </perfect-scrollbar>
            </v-dialog>
        </template>

        <template class="borde-card">
            <v-dialog v-model="dialogColonia" persistent max-width="500px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalColonia" :style="'display: none;'"></v-btn>
                </template>
                <v-card class="borde-card">
                    <ValidationObserver ref="formColonia" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">Agregar colonia</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto mt-5">
                            <div id="padre" v-show="isLoadingModalColonia">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingModalColonia" grid-list-md id="contenedor">
                                <v-row class="mt-0">
                                    
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Código postal" 
                                                class="pa-0 ma-0" 
                                                v-model="busca_cp" 
                                                :error-messages="errors" 
                                                required
                                                disabled
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Colonia" 
                                                class="pa-0 ma-0" 
                                                v-model="nombreColonia" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModalColonia()" 
                                :disabled="isSavingColonia"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardarColonia()" 
                                :disabled="invalid" 
                                :loading="isSavingColonia"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>

 
        <v-dialog v-model="dialogLayout" persistent max-width="500px">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayout"></v-btn>
            </template>
            <v-card>
                <ValidationObserver ref="formLayout" v-slot="{ invalid }">
                    <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                        <div class="headerModalStart">
                            <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                        </div>
                    </v-card-title>
                    <v-card-text class="card_texto">
                        <div id="padre" v-show="isLoadingLayout">
                            <div id="loading">
                                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                            </div>
                        </div>
                        
                        <v-container v-show="!isLoadingLayout" grid-list-md id="contenedor">
                            <v-divider class="divider"></v-divider>
                            <v-row>
                                <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                    <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                        <v-autocomplete
                                            outlined
                                            label="Cliente"
                                            :items="clientes"
                                            item-text="nombre"
                                            item-value="id"
                                            persistent-hint
                                            v-model="cliente_value"
                                            required
                                            :error-messages="errors" 
                                        >
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                    <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                        <v-file-input
                                            outlined
                                            label="Seleccionar el documento"
                                            v-model="archivo.archivo"
                                            accept=".xlsx"
                                            required 
                                            :error-messages="errors"
                                            prepend-icon=""
                                            append-icon="attach_file"
                                            id="fileDocument"
                                            @click:append="open"
                                        >
                                        </v-file-input>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end card_accion">
                        <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                        <v-btn class="btnGuardar" @click="guardarLayout()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalErrores"></v-btn>
            </template>
            <v-card class="borde-card">
                <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                        <v-btn
                            @click="cerrarModalErrores()"
                            small
                            slot="activator"
                            icon
                            text
                            class="v-btn-cerrar modal-pull-right"
                            absolute
                            top
                            right
                        >
                            <v-icon class="icono-cerrar"></v-icon>
                        </v-btn>
                    <div class="headerModalStart">
                        <h2 class="titleModalErrores">Errores de puestos</h2>
                    </div>
                </v-card-title>

                <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                    <div id="padre" v-show="isLoadingError">
                        <div id="loading">
                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                        </div>
                    </div>
                    
                    <v-container v-show="!isLoadingError" grid-list-md id="contenedor">
                        <v-row class="pa-0 ma-0">
                            <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                <table class="tbl-errores">
                                    <thead class="tbl-header">
                                        <tr>
                                            <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Puesto</th>
                                            <th style="border-top-right-radius: 20px;" class="errorth">Tipo de error</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="erroresModal.length == 0" >
                                            <td class="emptyTableErrores" colspan="2">
                                                No hay elementos para mostrar
                                            </td>
                                        </tr>
                                        <template v-else v-for="(error, index) in erroresModal">
                                            <template>
                                                <tr :key="index">
                                                    <td class="pl-7 errortd pt-4 pb-0">
                                                        <div class="textoTablaError01">{{error.nombre}}</div>
                                                    </td>
                                                    <td class="errortd"> <div class="textoTablaError03">{{error.comentarios}}</div> </td>
                                                </tr>
                                                <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                            </template>
                                        </template>
                                    </tbody>
                                </table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import apiRegistroPatronal from "../api/nominas/registroPatronal";
import apiClientes from "../api/clientes";
import apiEmpresas from "../api/empresas";
import apiZonaEconomica from "../api/nominas/zonaEconomica";
import catalogoApis from '../api/catalogos';
import apiClases from '../api/nominas/clases';
import apiRegimenFiscal from '../api/nominas/regimenFiscal';
import Datatable from '@/components/g-datatable/Datatable.vue';
import Notify from '@/plugins/notify';
import queries from '@/queries/RegistrosPatronales';
import GetUrlImg from "@/plugins/Urlimagen";
import gFunctions from '@/js/global_functions';
import bancosApis from '@/api/nominas/bancos';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';

import env from "@/plugins/enviroment";
import Echo from "laravel-echo";
window.Pusher = require('pusher-js');


export default {
    components: {
        'data-table': Datatable,
        VuetifyMoneyValidate
    },
    data() {
        return {
            columns     : [
                {
                    label: 'Clave',
                    name: 'clave',
                    filterable: true
                },
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Registro Patronal',
                    name: 'regimen_patronal',
                    filterable: true
                },
                {
                    label: 'RFC',
                    name: 'rfc',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters     : {AND:[]},
            tableData       : {},
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            registroPatronal        : {
                id                  : null,
                cliente_id          : null,
                clave               : '',
                nombre              : '',
                registro_patronal   : '',
                calle               : '',
                numero_interior     : null,
                numero_exterior     : null,
                estado_id           : null,
                municipio_id        : null,
                cp_id               : null,
                colonia_id          : null,
                telefono            : '',
                representante       : '',
                riesgo_de_trabajo   : null,
                clase_id            : null,
                rfc                 : '',
                apoderado           : '',
                clave_odesa         : null,
                antiguedad_fondo_ahorro: null,
                regimen_fiscal_id   : null,
                logo                : null,
                curp                : null,
                actividad_comercial : null,
                cuota_obrera        : false,
                activar_dos_contratos: false,
                calculo_prima_vacacional: false,
                fecha_calculo  : 'imss'

            },
            rol                 : null,
            userData            : this.$session.get('usuario'),
            accion              : null,
            tituloModal         : null,
            imagen              : "",
            dialog              : false,
            isLoading           : true,
            isSaving            : false,
            clientes            : [],
            empresas            : [],
            cliente_value       : null,
            empresa_value       : null,
            nombre              : null,
            rfc                 : null,
            regimen_patronal    :null,
            zonasEconomicas     : [],
            estado_id           : null,
            municipio_id        : null,
            cp_id               : null,
            estados             : [],
            municipios          : [],
            ciudades            : [],
            codigosPostales     : [],
            colonias            : [],
            clases              : [],
            regimenesFiscales   : [],      
            busca_cp            : "",
            estado              : "",
            municipio           : "", 
            isLoadingModal      : true,
            archivoImagen       : null,
            nombreImagen        : null,
            dialogBanco         : false,
            cuenta              : null,
            clabe               : null,
            ordenante           : null,
            emisora             : null,
            banco_id            : null,
            referencia          : null,
            cuentasBancarias    : [],
            bancos              : [],
            disableCURP         : true,
            direccionContrato   : "",
            showODESSA          : false,
            tipo_descuento      : 'porcentaje', //para odessa 
            monto_fa            : null,
            isDisabled          : true, //nuevo, habilita edición de bancos
            indexBanco          : null, //nuevo index para habilitar edicion
            bancosEliminados   : [], //nuevo, array de bancos eliminados,
            banco_principal    : false,
            options: {
                length: 11,
                locale: "es-MX",
                precision: 2,
                prefix: "$",
                suffix: "",
            },
            itemsTipoFechaCalculo: [
                {
                    valor:'imss',
                    descripcion: 'Fecha IMSS'
                },
                {
                    valor: 'ingreso',
                    descripcion: 'Fecha Ingreso'
                },
            ],
            controlWatch: false,

            mColonia                : false,
            dialogColonia           : false,
            isLoadingModalColonia   : false,
            nombreColonia           : null,
            isSavingColonia         : false,
            dialogSueldo            : false,


            frase                   :'Descargando archivo',
            overlayDesgarga         : false,
            archivo                 : {
                archivo             : null,
                cliente_id          : null,
            },
            dialogLayout            : false,
            isLoadingLayout         : false,
            erroresModal            : [],
            dialogErrores           : false,
            isLoadingError          : false,

            dialogOverlaySocket     : false,
            socketPorcentaje        : 0,
            socketTotal             : 0,
            socketProcesados        : 0,
            procesados              : 0,
            conError                : 0,
        }
    },
    watch:{
        'registroPatronal.calle':function(val){
            this.formatoDireccionContrato();
        },
        'registroPatronal.numero_interior':function(val){
            this.formatoDireccionContrato();
        },
        'registroPatronal.colonia_id':function(val){
            this.formatoDireccionContrato();
        },
        'estado':function(val){
            this.formatoDireccionContrato();
        },
        'municipio':function(val){
            this.formatoDireccionContrato();
        },


        busca_cp: function(val){
            if(val !== null){
                if(val.length == 5){
                    this.busca_codigos();
                    this.formatoDireccionContrato();
                    this.mColonia = true;
                }
                else{
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                    this.mColonia = false;
                }
            }
        },
        clabe:function(val){
            if (val.length == 3){
                let banco = this.bancos.find(element => element.clave == val.toString());  
                if (banco != undefined){
                    this.banco_id = banco.id;
                }
                else{
                    this.banco_id=0;
                }
            }
        },
        'registroPatronal.rfc':function(val){
            if(val.length == 13){
                this.disableCURP = false;
            }
            else{
                this.disableCURP = true;
                this.registroPatronal.curp = null;
            }
            
        },
        cliente_value:function(val){
            if(this.rol == 'root'){
                if(val != null){
                    let cliente = this.clientes.find(element=>element.id == val);
                    if(cliente.odesa == true){
                        this.showODESSA = true;
                    }
                    else{
                        this.showODESSA = false;
                        this.registroPatronal.clave_odesa = null;
                        this.registroPatronal.antiguedad_fondo_ahorro = null;
                    }
                }
            }
            else{
                if(this.datosLogin.cliente.odesa == true){
                    this.showODESSA = true;
                }
                else{
                    this.showODESSA = false;
                    this.registroPatronal.clave_odesa = null;
                    this.antiguedad_fondo_ahorro = null;
                }
                
            }
        },
        tipo_descuento: function(val) {
            if(this.controlWatch == true){
                this.monto_fa = null;
            }
        }
    },
    methods: {
        formatoDireccionContrato(){
            let colonia = this.colonias.find(element => element.id == this.registroPatronal.colonia_id);
            let nombreColonia = "";
            if(colonia != undefined){
                nombreColonia = colonia.nombre!=undefined ? colonia.nombre : '';
            }
            if(this.accion == 'add'){
                this.direccionContrato = ("CALLE "+this.registroPatronal.calle + " NÚMERO " + this.registroPatronal.numero_interior + " DE LA COLONIA " + nombreColonia + " CÓDIGO POSTAL " + this.busca_cp + " " + this.municipio + ", " + this.estado).toUpperCase()
            }
        },
        open(){
            document.getElementById("fileDocument").click();
        },
        almacenarImagen() {
            this.$refs.imagen.click();
        },
        onFilePickedImagen(evento) {
            const img = evento.target.files;
            this.nombreImagen = null;
            this.archivoImagen = null;
            if (img[0] !== undefined) {
                this.nombreImagen = img[0].name;
                if (this.nombreImagen.lastIndexOf(".") <= 0) {
                    return;
                }
                const archivo = new FileReader();
                archivo.readAsDataURL(img[0]);
                archivo.addEventListener("load", () => {
                    this.archivoImagen = img[0];
                });
            } else {
                this.nombreImagen = null;
                this.archivoImagen = null;
            }
        },
        eliminarLogo(){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el logo del registro patronal?",
                "Eliminar",
                () => {
                    let param = {id:this.registroPatronal.id};
                    apiRegistroPatronal.deleteLogo(param).then(response => {
                        Notify.Success("Logo eliminado", "El logo ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.registro_patronales.refetch();
                    })
                    .catch(err => {
                        gFunctions.errorParse(err.response.data.error);
                    });
                }
            )           
        },
        presicionNumeroPorcentaje(event, valor){
            if (/\.\d{5}/.test(valor)) 
                return event.preventDefault();
        },
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            if (this.rol == "root") {
                this.getClientes();
            }
             if (this.rol != "root") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters = { AND:[{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]};
            }
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        getClientes() {
            let params = {
                activo      : true,
                paginate    : false,
            };

            apiClientes.getClientes(params)
            .then(response => {
                this.clientes   = response.data;
            })
            .catch(error => {
                console.error(error);
            });
        },
        cambiaCliente(id, empresa =  null){
            let params = {
                activo      : true, 
                paginate    : false,
                cliente_id  : id 
            };

            apiEmpresas.getEmpresas(params).then((response) => {
                this.empresas   = response.data;
            })
            .catch (err => {
                console.log(err);
            })
        },
        async abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoadingModal  = true;
            
            //console.log(data);
            this.getClases();
            this.getRegimenesFiscales();
            if(accion == "add") {
                this.tituloModal = "Registrar Registro Patronal";

                this.imagen = "/static/modal/registroPatronalCreate.svg";
                this.getParam();

                setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
            } 
            else if(accion == "update"){
                this.tituloModal  = "Actualizar Registro Patronal";

                if (data.logo == null) {
                    this.imagen = "/static/modal/registroPatronalCreate.svg";
                } else {
                    this.registroPatronal.logo = data.logo;
                    this.imagen = GetUrlImg.getURL() + data.logo;
                }

                this.registroPatronal.id                    = data.id;
                this.cliente_value                          = data.cliente_id;
                this.registroPatronal.clave                 = data.clave;
                this.registroPatronal.nombre                = data.nombre;
                this.registroPatronal.registro_patronal     = data.registro_patronal;
                this.registroPatronal.calle                 = data.calle;
                this.registroPatronal.numero_interior       = data.numero_interior;
                this.registroPatronal.numero_exterior       = data.numero_exterior;
                this.registroPatronal.estado_id             = data.estado_id;
                this.registroPatronal.municipio_id          = data.municipio_id;
                this.registroPatronal.cp_id                 = data.cp_id;
                this.registroPatronal.colonia_id            = data.colonia_id;
                this.registroPatronal.telefono              = data.telefono;
                this.registroPatronal.representante         = data.representante;
                this.registroPatronal.riesgo_de_trabajo     = data.riesgo_de_trabajo;
                this.registroPatronal.clase_id              = data.clase_id;
                this.registroPatronal.rfc                   = data.rfc;
                this.registroPatronal.apoderado             = data.apoderado;
                this.registroPatronal.clave_odesa           = data.clave_odesa;
                this.registroPatronal.antiguedad_fondo_ahorro = data.antiguedad_fondo_ahorro;
                this.registroPatronal.regimen_fiscal_id     = data.regimen_fiscal_id;
                this.registroPatronal.curp                  = data.curp;
                this.registroPatronal.actividad_comercial   = data.actividad_comercial;
                this.registroPatronal.cuota_obrera          = data.cuota_obrera;
                this.registroPatronal.activar_dos_contratos = data.activar_dos_contratos;
                this.registroPatronal.calculo_prima_vacacional = data.calculo_prima_vacacional;
                this.registroPatronal.fecha_calculo         = data.fecha_calculo;
                this.cuentasBancarias = data.bancos;
                this.direccionContrato = data.direccion_contrato;
                this.tipo_descuento = data.tipo_descuento == null || data.tipo_descuento == '' ? 'porcentaje' : data.tipo_descuento;
                this.monto_fa       = data.monto_fa;
                await this.getCP(data.cp_id);
                this.controlWatch = true;

                setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
            }

            else if(accion == "bancos"){
                this.tituloModal  = "Registrar Banco";
                this.imagen = "/static/modal/registroPatronalBanco.svg";
                this.registroPatronal.id                    = data.id;
                this.cliente_value                          = data.cliente_id;
                this.registroPatronal.clave                 = data.clave;
                this.registroPatronal.nombre                = data.nombre;
                this.registroPatronal.registro_patronal     = data.registro_patronal;
                this.registroPatronal.calle                 = data.calle;
                this.registroPatronal.numero_interior       = data.numero_interior;
                this.registroPatronal.numero_exterior       = data.numero_exterior;
                this.registroPatronal.estado_id             = data.estado_id;
                this.registroPatronal.municipio_id          = data.municipio_id;
                this.registroPatronal.cp_id                 = data.cp_id;
                this.registroPatronal.colonia_id            = data.colonia_id;
                this.registroPatronal.telefono              = data.telefono;
                this.registroPatronal.representante         = data.representante;
                this.registroPatronal.riesgo_de_trabajo     = data.riesgo_de_trabajo;
                this.registroPatronal.clase_id              = data.clase_id;
                this.registroPatronal.rfc                   = data.rfc;
                this.registroPatronal.apoderado             = data.apoderado;
                this.registroPatronal.clave_odesa           = data.clave_odesa;
                this.registroPatronal.antiguedad_fondo_ahorro = data.antiguedad_fondo_ahorro;
                this.registroPatronal.regimen_fiscal_id     = data.regimen_fiscal_id;
                this.registroPatronal.actividad_comercial   = data.actividad_comercial;
                this.cuentasBancarias = data.bancos;
                await this.getCP(data.cp_id);
                setTimeout(function(){ document.getElementById("btnModalBanco").click(); }, 100);
            }

            this.isLoadingModal  = false;
        },
        cerrarModal() {
            this.resetValues();
            this.dialog     = false;
            this.dialogBanco= false;
            this.isSaving   = false;
            this.dialogLayout = false;
        },
        async resetValues() {
            this.registroPatronal = {
                id                  : null,
                cliente_id          : null,
                clave               : '',
                nombre              : '',
                registro_patronal   : '',
                calle               : '',
                numero_interior     : null,
                numero_exterior     : null,
                estado_id           : null,
                municipio_id        : null,
                cp_id               : null,
                colonia_id          : null,
                telefono            : '',
                representante       : '',
                riesgo_de_trabajo   : null,
                clase_id            : null,
                rfc                 : '',
                apoderado           : '',
                clave_odesa         : null,
                antiguedad_fondo_ahorro: null,
                regimen_fiscal_id   : null,
                logo                : null,
                curp                : null,
                actividad_comercial : null,
                cuota_obrera        : false,
                activar_dos_contratos: false,
                calculo_prima_vacacional: false,
                fecha_calculo       : 'imss',
            };

            this.cuenta   = null;
            this.banco_id = null;
            this.clabe    = null;
            this.referencia = null;
            this.ordenante = null;
            this.emisora = null;
            this.tipo_descuento = 'porcentaje';
            this.monto_fa = null;
            this.controlWatch = false;
            if(this.rol == 'root'){
                this.showODESSA = false;
            }
            this.direccionContrato = null;
            this.mColonia   = false;
            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                } 
                if(this.$refs.formLayout != undefined || this.$refs.formLayout != null){
                    this.$refs.formLayout.reset();
                } 
                
                this.cliente_value  = null;
                this.nombre = null;
                this.regimen_patronal = null;
                this.rfc = null;
                this.busca_cp       = null;
                this.colonias       = [];
                this.estado         = "";
                this.municipio      = "";
                this.nombreImagen   = null;
                this.archivoImagen  = null;
            });
            this.isDisabled = true;
            this.bancosEliminados = [];


            this.frase                   = 'Descargando archivo';
            this.overlayDesgarga         = false;
            this.archivo                 = {
                archivo             : null,
                cliente_id          : null
            };
            this.dialogLayout            = false;
            this.isLoadingLayout         = false;
            this.erroresModal            = [];
            this.dialogErrores           = false;
            this.isLoadingError          = false;
            this.dialogOverlaySocket     = false;
            this.socketPorcentaje        = 0;
            this.socketTotal             = 0;
            this.socketProcesados        = 0;
            this.procesados              = 0;
            this.conError                = 0;




        },
        async loadModalData(){
            await this.resetValues();
        },
        setFilters() {
            this.filters = { AND:[]};

            let nombre = this.nombre;
            let regimen_patronal = this.regimen_patronal
            let rfc = this.rfc
            let cliente = this.cliente_value;
            
            if (this.rol != "root") {
                cliente = this.datosLogin.cliente_id;
            } 
            this.isLoading = true;

            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }
            
            if(regimen_patronal != null && regimen_patronal != undefined && regimen_patronal != ""){
                this.filters.AND.push({column:'REGISTRO_PATRONAL', operator:'ILIKE', value:"%"+regimen_patronal+"%"});
            }
            
            if(rfc != null && rfc != undefined && rfc != ""){
                this.filters.AND.push({column:'RFC', operator:'ILIKE', value:"%"+rfc+"%"});
            }

            this.paginationData.numberPage = 1;
            this.$apollo.queries.registro_patronales.refetch();
            this.resetValues();
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                this.registroPatronal.cliente_id       = this.cliente_value;
                if(this.rol != 'root'){
                    this.registroPatronal.cliente_id  = this.datosLogin.cliente_id;;
                }

                this.isLoading = true;
                let formData = new FormData();

                formData.append('cliente_id', this.registroPatronal.cliente_id.toString());
                formData.append('clave', this.registroPatronal.clave.toString());
                formData.append('nombre', this.registroPatronal.nombre.toString());
                formData.append('registro_patronal', this.registroPatronal.registro_patronal.toString());
                formData.append('calle', this.registroPatronal.calle.toString());
                formData.append('numero_interior', this.registroPatronal.numero_interior.toString());
               
                formData.append('estado_id', this.registroPatronal.estado_id.toString());
                formData.append('municipio_id', this.registroPatronal.municipio_id.toString());
                formData.append('cp_id', this.registroPatronal.cp_id.toString());
                formData.append('colonia_id', this.registroPatronal.colonia_id.toString());
                formData.append('telefono', this.registroPatronal.telefono.toString());
                formData.append('representante', this.registroPatronal.representante.toString());
                formData.append('riesgo_de_trabajo', this.registroPatronal.riesgo_de_trabajo.toString());
                formData.append('clase_id', this.registroPatronal.clase_id.toString());
                formData.append('rfc', this.registroPatronal.rfc.toString());
                formData.append('apoderado', this.registroPatronal.apoderado.toString());
                formData.append('regimen_fiscal_id', this.registroPatronal.regimen_fiscal_id.toString());
                formData.append('actividad_comercial', this.registroPatronal.actividad_comercial.toString());
                formData.append('cuota_obrera',this.registroPatronal.cuota_obrera.toString());
                formData.append('activar_dos_contratos', this.registroPatronal.activar_dos_contratos);
                formData.append('calculo_prima_vacacional',this.registroPatronal.calculo_prima_vacacional);
                formData.append('fecha_calculo',this.registroPatronal.fecha_calculo);
               

                if(this.direccionContrato != null && this.direccionContrato != ''){
                     formData.append('direccion_contrato', this.direccionContrato.toString());
                }
                if(this.registroPatronal.numero_exterior != null && this.registroPatronal.numero_exterior != ''){
                    formData.append('numero_exterior', this.registroPatronal.numero_exterior.toString());
                }
                if(this.registroPatronal.curp != null && this.registroPatronal.curp != ''){
                    formData.append('curp', this.registroPatronal.curp.toString());
                }
                else{
                    formData.append('curp','');
                }

                if(this.registroPatronal.clave_odesa != null && this.registroPatronal.clave_odesa != ''){
                    formData.append('clave_odesa', this.registroPatronal.clave_odesa.toString());
                }

                if(this.registroPatronal.antiguedad_fondo_ahorro != null && this.registroPatronal.antiguedad_fondo_ahorro != ''){
                    formData.append('antiguedad_fondo_ahorro', this.registroPatronal.antiguedad_fondo_ahorro.toString());
                }

                if(this.showODESSA == true){
                    if(this.tipo_descuento != null && this.tipo_descuento != ''){
                        formData.append("tipo_descuento", this.tipo_descuento.toString());
                    }
                    else{
                        formData.append("tipo_descuento", '');
                    }

                    if(this.monto_fa != null && this.monto_fa != ''){
                        formData.append("monto_fa", this.monto_fa.toString());
                    }
                    else{
                        formData.append("monto_fa", '');
                    }
                }

                if (this.archivoImagen != null) {
                    formData.append("archivo", this.archivoImagen);
                    if (this.registroPatronal.logo != null) {
                        formData.append("logo", this.registroPatronal.logo);
                    }
                }

                if(this.accion === "add") {
                    apiRegistroPatronal.store(formData)
                    .then((response) => {
                        this.$apollo.queries.registro_patronales.refetch();
                        Notify.Success("Operación exitosa", "El Registro Patronal se guardó satisfactoriamente.");
                        this.cerrarModal();
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoading = false;
                        gFunctions.errorParse(err.response.data.error);
                        
                    });
                }
                else if(this.accion === "update") {
                    formData.append('id',this.registroPatronal.id);
                    apiRegistroPatronal.update(formData)
                    .then((response) => {
                        this.$apollo.queries.registro_patronales.refetch();
                        Notify.Success("Operación exitosa", "El Registro patronal se actualizó satisfactoriamente.");
                        this.cerrarModal();
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoading = false;
                        gFunctions.errorParse(err.response.data.error);
                    });
                }
            });
        },
        eliminar(data) {
            Notify.Alert(
                "¿Estás seguro de eliminar este registro patronal?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiRegistroPatronal.delete(data.id).then(response => {
                        this.$apollo.queries.registro_patronales.refetch();
                        Notify.Success("Registro Patronal Eliminado", "El registro patronal ha sido eliminado satisfactoriamente");
                    })
                    .catch(err => {
                        this.isLoading = false;
                        console.error(err);
                        gFunctions.errorParse(err.response.data.error);
                    });
                },
                null,
                true,
                true
            )
        },
        zonaEconomica(){
            let parametros = {
                activo      : true, 
                paginate    : false,
                order       : 'asc'
            }
            apiZonaEconomica.find(parametros).then(response =>{
                this.zonasEconomicas = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast(err.response.data.error);
            });
        },
        getParam(estado = null, municipio = null, ciudad = null, cp = null, colonia=null){
            let self            = this;
            catalogoApis.getEstados(this.parametros_base)
                .then((response) => {
                    self.estados = response.data;
                    if(estado != null) {
                        self.estado_id = estado;
                        self.cambioEstadoM(estado, municipio, cp, colonia);
                        self.cambioEstadoC(estado,ciudad);
                    }
                    if(estado == null) {
                        //self.finaliza_carga = true;
                    }
                })
                .catch(err => {
                    console.error(err);
                    this.loading_guardar = false;
                    Notify.ErrorToast("Ocurrió un error al cargar los estados.")
                });
        },
        cambioEstadoM(estado = null, municipio = null, cp = null, colonia = null){
            let parametros = { activo:true, paginate:false, estado_id:estado, order:"asc" };
            catalogoApis.getMunicipios(parametros).then((response) => {
                this.municipios =  response.data;
                if(municipio != null){
                    this.registroPatronal.municipio_id = this.municipio_id = municipio;
                    this.cambioMunicipio_CP(municipio, cp , colonia);
                }
            })
            .catch(err =>{
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los municipios.");
            });
        },
        cambioEstadoC(estado = null, ciudad = null){
            let parametros = { activo:true, paginate: false, estado_id: estado, order:"asc" };
            catalogoApis.getCiudades(parametros).then((response) => {
                this.ciudades = response.data;
                
            })
            .catch(err => {
                self.loading_guardar = false;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las ciudades.");
            });
        },
        cambioMunicipio_CP(municipio = null, cp = null, colonia = null){
            if(municipio != null){
                let parametros = { activo:true, paginate:false, municipio_id: municipio, order:"asc"}
                catalogoApis.getCP(parametros).then((response) => {
                    this.codigosPostales = response.data;
                    if(cp != null) {
                        this.registroPatronal.cp_id = this.cp_id = cp;
                        this.cambioCP(cp, colonia);
                    }
                })
                .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los codigos postales.");
                });
            }
        },
        cambioCP(cp = null, colonia = null){
            if(cp!=null){
                let parametros = { activo:true, paginate:false, codigo_postal_id: cp, order:"asc"}
                catalogoApis.getColonias(parametros).then((response) => {
                this.colonias = response.data;
                if(colonia != null) {
                    this.registroPatronal.colonia_id  = colonia;
                }
                
                })
                .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las colonias.");
                });
            }  
        },
        busca_codigos() {
            let self = this;
            let params = {
                activo: true,
                nombre: this.busca_cp,
                include_padres: true,
            };
            catalogoApis
                .getCP(params)
                .then((response) => {
                let result = response.data;
                if (result.length == 0) {
                    Notify.ErrorToast("No se encontro el Código postal.");
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                    this.mColonia   = false;
                    return;
                }
                this.estado = result[0].nomb_estado;
                this.municipio = result[0].nomb_mun;
                this.registroPatronal.estado_id = result[0].estado_id;
                this.registroPatronal.municipio_id = result[0].municipio_id;
                this.registroPatronal.cp_id = result[0].id;

                catalogoApis.getColonias({ codigo_postal_id: result[0].id, order: "asc", paginate: false }).then((response) => {
                    self.colonias = response.data;
                })
                .catch((e) => {
                    Notify.ErrorToast(
                        "Se presento un problema al cargar las colonias."
                    );
                    return;
                    });
                })
                .catch((e) => {
                    console.log(e);
                    Notify.ErrorToast(
                        "Ocurrió un error al cargar los estados y ciudades."
                    );
                });
        },
        async getCP(id) {
            await catalogoApis.getCodigoPostal(id).then((response) => {
                this.busca_cp = response.nombre;
                this.busca_codigos();
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los codigos postales.");
            });
        },
        abrirModalColonia(){
            this.dialogColonia = true;
            this.isLoadingModalColonia = false;
        },
        cerrarModalColonia(){
            this.dialogColonia = false;
            this.nombreColonia = null;
            this.isSavingColonia = false;
        },
        guardarColonia(){
            this.$refs.formColonia.validate().then(success => {
                this.isSavingColonia = true;
                if(!success) {
                    this.isSavingColonia = false;
                    return;
                }
                
                let parametros = {
                    nombre : this.nombreColonia,
                    codigo_postal_id : this.registroPatronal.cp_id
                };
                
                catalogoApis.addColonia(parametros).then((response) => {
                    Notify.Success("Operación exitosa", "La calonia se agrego correctamnte.");
                    this.busca_codigos()
                    this.cerrarModalColonia();
                    this.registroPatronal.colonia_id = response.data.id;
                    this.$nextTick(() => {
                        this.$refs.formColonia.reset();
                    });
                })
                .catch(err => {
                    this.isSavingColonia = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            });
        },

        getClases(){
            let parametros = {
                activo      : true, 
                paginate    : false,
                order       : 'asc'
            }
            apiClases.find(parametros).then(response =>{
                this.clases = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast(err.response.data.error);
            });
        },
        getRegimenesFiscales(){
            let parametros = {
                activo      : true, 
                paginate    : false,
                order       : 'asc'
            }
            apiRegimenFiscal.find(parametros).then(response =>{
                this.regimenesFiscales = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast(err.response.data.error);
            });
        },
        agregarCuenta(){
            if( this.cuenta    == null || this.cuenta    == "" ||
                this.banco_id  == null || this.banco_id  == "" ||
                this.clabe     == null || this.clabe     == "" ||
                this.ordenante == null || this.ordenante == "" ||
                this.emisora   == null || this.emisora   == "" ||
                this.referencia == null || this.referencia == ""
            ){
                Notify.ErrorToast("Favor de agregar todos los campos.");
            }
            else if(this.cuenta.length < 10 || this.cuenta.length > 24){
                Notify.ErrorToast("Solo se puede ingresar cuentas bancarias entre 10 y 24 digitos.");
            }
            else if(this.clabe.length != 18){
                Notify.ErrorToast("La clabe interbancaria tiene que ser de  18 digitos.");
            }
            else{
                var repetida = false;
                var cuentas = this.cuentasBancarias;
                         
                for (let i = 0; i < cuentas.length; i++) {
                    let temp_banco = cuentas[i].banco_id;
                    
                    if(cuentas[i].banco_id == undefined)
                        temp_banco = cuentas[i].banco.id;

                    if(this.banco_id == temp_banco && this.cuenta == cuentas[i].cuenta_bancaria && this.clabe == cuentas[i].clabe_bancaria ){
                        repetida = true;
                    }
                }
                if(repetida ){
                    Notify.ErrorToast("No se puede agregar la misma cuenta dos veces.");
                }
                else{
                    
                    const banco = this.bancos.find(element => element.id == this.banco_id);
                    if(this.cuentasBancarias.length == 0){
                        this.banco_principal = true
                    }
                    else{
                        this.banco_principal = false
                    }
                    let cuentas = {id: 0, banco_id:this.banco_id, cuenta_bancaria: this.cuenta, clabe_bancaria: this.clabe,cuenta_ordenante: this.ordenante,cuenta_emisora: this.emisora, referencia: this.referencia, tipo_cuenta: "BANCO",
                        banco:{nombre: banco.nombre}, principal:this.banco_principal}
                    this.cuentasBancarias.push(cuentas);
                    this.banco_id = null;
                    this.cuenta = null;
                    this.clabe = null;
                    this.ordenante = null;
                    this.emisora = null;
                    this.referencia = null;
                }
            }
        },
        eliminaCuenta(elementIndex, i){
            this.bancosEliminados.push(i.id)
            this.cuentasBancarias = this.cuentasBancarias.filter((element, index) => index != elementIndex )
        },
        editarCuenta(elementIndex, item = null){
            //console.log(item);
            this.indexBanco = elementIndex
            this.isDisabled = false
            this.banco_principal = item.principal

        },
        cuentaPrincipal(item){
            console.log(item);
            this.cuentasBancarias.forEach((element,index) => {

                if(item == index){
                    if(element.principal == false){
                        element.principal = true
                    }
                }else{
                    element.principal = false
                }

                //console.log(element);
            })
        },
        async getBancos(){
            let self            = this;
            let param = { activo:true, paginate:false, order:"asc"}
            await bancosApis.find(param).then((response) => {
                self.bancos = response.data;
            })
            .catch(err => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar los bancos.")
            });
        },
        guardarBancos() {
            this.isSaving = true;
            if(this.cuentasBancarias.length == 0) {
                this.isSaving = false;
                Notify.ErrorToast("Agregar al menos una cuenta bancaria.")
                return;
            }
            
            console.log(this.cuentasBancarias);
            let objRegistroPatronal = {
                bancos              : JSON.stringify(this.cuentasBancarias),
                registro_patronal_id: this.registroPatronal.id,
                bancos_eliminados: JSON.stringify(this.bancosEliminados)
            }
            
            this.isLoading = true;
            apiRegistroPatronal.bancos(objRegistroPatronal).then((response) => {
                this.$apollo.queries.registro_patronales.refetch();
                Notify.Success("Operación exitosa", "El Registro patronal se actualizó satisfactoriamente.");
                this.cerrarModal();
            })
            .catch(err => {
                this.isSaving = false;
                this.isLoading = false;
                gFunctions.errorParse(err.response.data.error);
            });
        },

        async descargarLayout(){
            let url = "registro-patronal/descargarLayout";
            const FileDownload = require("js-file-download");
            let today = new Date();
            this.isSaving = true;
            let nombre;
            nombre =
                    "layout_de_importacion_de_rp_" +
                    today.getDate() +
                    "_" +
                    (today.getMonth() + 1) +
                    "_" +
                    today.getFullYear() +
                    "_" +
                    today.getHours() +
                    "-" +
                    today.getMinutes() +
                    "-" +
                    today.getSeconds() +
                    ".xlsx";
            try {
                await Vue.axios({
                    method: "GET",
                    responseType: "blob",
                    url: url,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.overlayDesgarga = false;
                    this.isSaving = false
                })
                .catch(err => {
                    this.overlayDesgarga = false;
                    this.isSaving = false
                    console.log(err)
                });
            } catch (e) {
                this.overlayDesgarga = false;
                this.isSaving = false;
                console.log(e);
            }
        },

        abrirModalLayout(){
            this.tituloModal  = 'Subir layout para agregar Registros Patronales.';
            this.dialogLayout = true;
            if(this.rol == "root"){
                this.getClientes();
            }
        },

        guardarLayout(){
            this.$refs.formLayout.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                if(this.rol == "root"){
                    this.archivo.cliente_id = this.cliente_value;
                }
                else {
                    this.archivo.cliente_id = this.datosLogin.cliente_id;
                }
                let formData = new FormData();
                formData.append("archivo", this.archivo.archivo);
                formData.append("cliente_id", this.archivo.cliente_id);
                
                Vue.axios({
                    method: "post",
                    url: "registro-patronal/importar",
                    data: formData,
                    config: { headers: { "Content-Type": "multipart/form-data" } }
                })
                .then((response) => {
                    if (response.status == 200) {
                        //Notify.Success("Operación exitosa", "Se ha realizado la operación correctamente.");
                        this.cerrarModal();
                    }
                })
                .catch(err => {
                    this.isSaving = false;
                    Notify.ErrorToast("Error al subir archivo.");
                    this.$apollo.queries.registro_patronales.refetch();
                });
            });
        },
        socketProcesando(){
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: 'ASDASD2121',
                wsHost: env.serverURL.url,
                wsPort: env.pusherPort,
                wssPort: env.pusherPort,
                forceTLS: env.serverURL.tls,
                disableStats: true,
                enabledTransports: ['ws', 'wss']
            });
            window.Echo.channel(`progreso-importar-registro-patronal-${this.datosLogin.id}`).listen('ImportarRegistroPatronalProgress', (e) => {
                this.dialogOverlaySocket = true;
                this.socketPorcentaje   = e.message.porcentaje;
                this.socketTotal        = e.message.total;      
                this.socketProcesados   = e.message.procesados;
                if(e.message.porcentaje == 100){
                    this.procesados = e.message.procesados;
                    this.conError   = e.message.errores;

                    if(e.message.errores.length == 0){
                        this.dialogOverlaySocket = false;
                        Notify.Success("Operación exitosa");
                        this.$apollo.queries.registro_patronales.refetch();
                    }
                    else{
                        this.dialogOverlaySocket = false;
                        this.erroresModal = e.message.errores;
                        this.dialogErrores = true;
                        this.$apollo.queries.registro_patronales.refetch();
                    }

                    setTimeout(() => {
                        this.resetProgreso();
                    },5000);
                }
            });
        },
        resetProgreso(){
            this.socketPorcentaje       = 0;
            this.socketTotal            = 0;
            this.socketProcesados       = 0;
            this.procesados             = 0;
            this.conError               = 0;
        },
        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresModal = [];
        },
    },
    mounted(){
        this.$apollo.queries.registro_patronales.skip = false;
    },
    created () {
        this.listar();
        this.getBancos();
        this.socketProcesando();
    },
    apollo: {
        registro_patronales: {
            query       : queries.registrosPatronalesTableQuery,
            fetchPolicy : 'no-cache',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.registro_patronales;   
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .tituloOtrasAcciones{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }
    ::v-deep .v-input.input-field .v-label {
        pointer-events: auto;
    }
    .cursor-pointer{
      cursor:pointer;
    }

    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: 210px;
        padding-top: 30px;
        background-color: #FFF;
    }
    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1E2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }


    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }
</style>
