import gql from 'graphql-tag';

const registrosPatronalesTableQuery = gql`
    query registro_patronales($whereConditions:RegistroPatronalesWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        registro_patronales(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                clave,
                nombre,
                registro_patronal,
                rfc,
                cliente_id,
                calle,
                numero_interior,
                numero_exterior,
                estado_id,
                municipio_id,
                cp_id,
                colonia_id,
                telefono,
                representante,
                riesgo_de_trabajo,
                clase_id,
                rfc,
                apoderado,
                regimen_fiscal_id,
                logo,
                curp,
                actividad_comercial,
                direccion_contrato,
                cuota_obrera,
                activar_dos_contratos,
                calculo_prima_vacacional,
                fecha_calculo,
                clave_odesa,
                antiguedad_fondo_ahorro,
                tipo_descuento,
                monto_fa,
                bancos{
                    id,
                    banco_id,
                    cuenta_bancaria,
                    clabe_bancaria,
                    cuenta_ordenante,
                    cuenta_emisora,
                    referencia,
                    tipo_cuenta,
                    banco{
                        nombre
                    },
                    principal
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { registrosPatronalesTableQuery };

export default queries;
